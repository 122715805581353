@import "../node_modules/bootstrap/scss/bootstrap";

$theme-colors: (
    "info": tomato,
    "danger": teal
);

.accordion-body {
  display: flex;
  position: relative;
  background: rgba(0,0,0,0.5);
  color: white;
  z-index: 9999;
}

.accordion-button {
  background: rgba(0,0,0,0.5);
  color: white;
}

.navbar .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.btn-link {
  padding: 0;
}

.col {
  position: relative;
  z-index: 999;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stayline {
  display: inline-block;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

footer {
  bottom: 5px;
  z-index: 9999;
  position: fixed;
}
